
import { api } from "@/api/api";
import { ApiArticleDtoType } from "@/api/generated/Api";
import EditArticleModal from "@/components/course/economy/EditArticleModal.vue";
import NewArticleModal from "@/components/course/economy/NewArticleModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { CourseOrderPaymentType } from "@/shared/enums/courseOrderPaymentType.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import {
  calculateTotalSum,
  getArticleName,
  getArticlesByArticleNos,
  mapOrderToApiUpdateDraftDto,
} from "@/shared/helpers/courseOrderHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { OrderLineWithUUID, OrderWithUUID } from "@/shared/interfaces/OrderWithUIID.interface";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseSingleOrderTable",
  components: {
    BaseTableFiltered,
    BaseModal,
    EditArticleModal,
    NewArticleModal,
    BaseConfirmModalForm,
    BaseTooltipIconButton,
  },
  emits: ["updateOrderLines"],
  props: {
    orderLinesWithUUID: {
      type: Array as PropType<OrderLineWithUUID[]>,
      required: true,
    },
    orderValuesWithUUID: {
      type: Object as PropType<OrderWithUUID>,
      required: true,
    },
    articleNos: {
      type: Array as PropType<string[]>,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const modalData = ref(getInitialModalData());
    const deleteModalData = ref(getInitialModalData());
    const createModalData = ref(getInitialModalData());
    const articles = ref<ApiArticleDtoType[]>([]);
    const filteredArticles = ref<ApiArticleDtoType[]>([]);

    const isEditableContra = computed(
      () =>
        props.orderValuesWithUUID.contraEntryOrderId &&
        props.orderValuesWithUUID.paymentType !== CourseOrderPaymentType.severalDeductions
    );

    // TODO: This is going to be removed once it's included by BE - No task existing yet, Sven Erik considering to include it.
    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const response = (await api.economy.getCourseEconomyBaseAsync()).data;
        if (response.articles) {
          articles.value = response.articles;
        }
        filteredArticles.value = getArticlesByArticleNos(props.articleNos, articles.value);
      });
    });

    const showDeleteArticleModal = useOpenModal(ModalType.Delete, "artikkel", deleteModalData);

    const addArticle = useOpenModal(ModalType.Add, "artikkel", createModalData);

    const editArticle = useOpenModal(
      ModalType.Edit,
      isEditableContra ? "artikkellinje - Kreditordre" : "artikkellinje - Ordre",
      modalData
    );

    const deleteArticle = async (modalData: ModalBaseData) => {
      const singleOrderWithUUID = deepCloneObject(props.orderValuesWithUUID);
      if (!singleOrderWithUUID?.orderLines?.length) {
        return;
      }
      const indexOfItem = singleOrderWithUUID.orderLines.findIndex(
        (ol) => ol.temporaryId === modalData.existingItem.temporaryId
      );
      if (indexOfItem === -1) {
        return;
      }
      singleOrderWithUUID.orderLines.splice(indexOfItem, 1);
      await updateOrderDraft(singleOrderWithUUID, modalData.modalType, () => {
        deleteModalData.value.showModal = false;
      });
    };

    const closeModal = () => {
      modalData.value.showModal = false;
    };

    const updateTableValuesFromModal = (updatedOrderLines?: OrderLineWithUUID[] | null) => {
      if (!updatedOrderLines) {
        return;
      }
      emit("updateOrderLines", updatedOrderLines);
    };

    const updateOrderDraft = async (
      singleOrderWithUUID: OrderWithUUID,
      modalType: ModalType,
      onFinished: () => void
    ) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const orderValuesWithUserId = mapOrderToApiUpdateDraftDto(singleOrderWithUUID);
        orderValuesWithUserId.orderLines?.map((orderline) =>
          orderline.user?.id === undefined ? delete orderline.user : orderline
        );

        if (route.name === SingleCourseRouteNames.CourseEconomyNew) {
          updateTableValuesFromModal(singleOrderWithUUID.orderLines);
          onFinished();
          return;
        }
        if (!orderValuesWithUserId) {
          return;
        }
        const updateDraftResponse = await api.order.updateDraft(singleOrderWithUUID.id, orderValuesWithUserId);
        if (updateDraftResponse.status === 204) {
          const articleAction = modalType == ModalType.Delete ? "slettet" : "oppdatert";
          openNotification(
            store,
            NotificationItemType.Success,
            `${modalType == ModalType.Add ? "Artikler" : "Artikkel"} ${articleAction}`
          );
          updateTableValuesFromModal(singleOrderWithUUID.orderLines);
          onFinished();
        }
      });
    };

    const headers = computed(() => [
      { text: "Artikkel #", value: "articleNo" },
      ...(props.isEditable ? [{ text: "Handlinger", value: "actions" }] : []),
      { text: "Artikkelnavn", value: "articleName" },
      { text: "Tilleggstekst", value: "description" },
      { text: "Deltaker", value: "user.name" },
      { text: "Enhetspris", value: "amount" },
      { text: "Antall", value: "quantity" },
      { text: "Rabatt", value: "discount" },
      { text: "Beløp inkl MVA", value: "totalAmount" },
    ]);

    return {
      headers,
      modalData,
      deleteModalData,
      createModalData,
      articles,
      formatCurrency,
      calculateTotalSum,
      updateTableValuesFromModal,
      getArticleName,
      closeModal,
      editArticle,
      deleteArticle,
      showDeleteArticleModal,
      updateOrderDraft,
      addArticle,
      filteredArticles,
      isEditableContra,
    };
  },
});
