
import { api } from "@/api/api";
import {
  ApiArticleDtoType,
  ApiGenerateTemplateDto,
  ApiOrderLineDto,
  ApiGetCourseParticipantKursAdminDto,
} from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateArrayNotEmpty, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore, useRoute } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, PropType, ref, onMounted } from "@vue/composition-api";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { OrderWithUUID } from "@/shared/interfaces/OrderWithUIID.interface";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import { useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { ParticipantRoles } from "@/shared/enums/ParticipantRoles.enum";

export default defineComponent({
  name: "NewArticleModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      validator: (value: string) => (Object.values(ModalType) as string[]).includes(value),
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    articles: {
      type: Array as PropType<ApiArticleDtoType[]>,
      required: true,
    },
    orderValuesWithUUID: {
      type: Object as PropType<OrderWithUUID>,
      required: true,
    },
  },
  emits: ["close", "updateOrderDraft"],
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const restrictedAccessApi = useRestrictedAccessApi();

    const selectedArticles = ref<string[]>([]);
    const selectedParticipantUserId = ref<number>();
    const courseParticipants = ref<ApiGetCourseParticipantKursAdminDto[]>([]);
    const orderTemplates = ref<ApiGenerateTemplateDto[]>([]);
    const singleOrderWithUUID = deepCloneObject(props.orderValuesWithUUID);

    const handleSubmit = () => {
      const isValid = getValidatableRef(refs.addArticleForm)?.validate();
      if (!isValid) {
        return;
      }

      globalLoadingWrapper({ blocking: true }, async () => {
        const articlesTemplate = await getArticlesTemplate();
        if (!articlesTemplate || articlesTemplate.length < 1) {
          openNotification(store, NotificationItemType.Error, `Finner ikke template for ny artikler`);
          return;
        }

        articlesTemplate.map((template) => {
          const newOrderLine = getArticleTemplateInitialValues(template);
          singleOrderWithUUID.orderLines?.push(newOrderLine);
        });
        emit("updateOrderDraft", singleOrderWithUUID, props.modalType, () => emit("close"));
      });
    };

    const getArticlesTemplate = async () => {
      orderTemplates.value = [
        {
          courseId: singleOrderWithUUID.courseId,
          customerUserId: singleOrderWithUUID.participant?.userId ?? 0,
          invoiceRecipientUserId: singleOrderWithUUID.invoiceRecipient?.userId ?? 0,
          userIds: [selectedParticipantUserId.value ?? 0],
          articleNos: selectedArticles.value,
        },
      ];
      const orderTemplateResponse = (await api.order.getTemplates(orderTemplates.value)).data;
      return orderTemplateResponse[0]?.orderLines;
    };

    onMounted(() => {
      globalLoadingWrapper({ blocking: true }, async () => {
        courseParticipants.value = (
          await restrictedAccessApi.getCourseParticipants(+route.params.id, {
            IsActive: true,
            RoleNames: [ParticipantRoles.Student],
          })
        ).data;
      });
    });

    return {
      handleSubmit,
      courseParticipants,
      selectedArticles,
      selectedParticipantUserId,
      validateArrayNotEmpty,
      validateNotEmpty,
    };
  },
});
const getArticleTemplateInitialValues = (orderLine?: ApiOrderLineDto) => ({
  courseAreaId: orderLine?.courseAreaId ?? 0,
  employeeId: orderLine?.employeeId ?? 0,
  projectNo: orderLine?.projectNo ?? 0,
  productNo: orderLine?.productNo ?? 0,
  accountingDepartment: orderLine?.accountingDepartment ?? 0,
  teachingMethod: orderLine?.teachingMethod ?? 0,
  employeeNo: orderLine?.employeeNo ?? 0,
  articleNo: orderLine?.articleNo ?? "",
  amount: orderLine?.amount ?? 0,
  discount: orderLine?.discount ?? 0,
  quantity: orderLine?.quantity ?? 0,
  user: orderLine?.user,
  temporaryId: generateUuid(),
});
