export enum CourseOrderButtonValues {
  SendAvailable = "sendAvailable",
  ValidateAvailable = "validateAvailable",
  ContraAvailable = "contraAvailable",
  CancelAvailable = "cancelAvailable",
  DeleteAvailable = "deleteAvailable",
  EditAvailable = "editAvailable",
  QueueAvailable = "queueAvailable",
  Other = "",
}
