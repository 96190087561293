var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.isCustomerOrganization,
      callback: function callback($$v) {
        _vm.isCustomerOrganization = $$v;
      },
      expression: "isCustomerOrganization"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Firma",
      "value": true
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Person",
      "value": false
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": _vm.isCustomerOrganization ? 'Firma' : 'Person',
      "items": _vm.isCustomerOrganization ? _vm.customerOrganizations : _vm.customerPersons,
      "rules": _vm.validateNotEmpty,
      "item-text": function itemText(item) {
        return _vm.getCustomerName(item);
      },
      "return-object": ""
    },
    model: {
      value: _vm.selectedOrderCustomer,
      callback: function callback($$v) {
        _vm.selectedOrderCustomer = $$v;
      },
      expression: "selectedOrderCustomer"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }