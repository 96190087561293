
import { ApiGetCustomerDto, ApiGetOrderDto } from "@/api/generated/Api";
import BaseIconCard from "@/components/shared/BaseIconCard.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { CourseOrderCustomerType } from "@/shared/enums/CourseOrderCustomerType.enum";
import { CourseOrderPaymentType } from "@/shared/enums/courseOrderPaymentType.enum";
import { intlPhoneCodes } from "@/shared/enums/intlPhoneCodes.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import {
  labelMap,
  mapToOrderParticipantAndInvoiceRecipient,
  OrderCustomer,
  reduceToOrderCustomer,
} from "@/shared/helpers/courseOrderHelpers";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateIsEmail, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useRouter } from "@/shared/useHelpers";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import ChangeOrderCustomerModal from "./ChangeOrderCustomerModal.vue";

export default defineComponent({
  name: "SingleOrderDetailValues",
  components: { BaseSheetHeader, BaseIconCard, BaseModal, ChangeOrderCustomerModal },
  emits: ["updateOrderValues", "cancelEdit"],
  props: {
    order: {
      type: Object as PropType<ApiGetOrderDto>,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    customerPersons: {
      type: Array as PropType<ApiGetCustomerDto[]>,
      required: true,
    },
    customerOrganizations: {
      type: Array as PropType<ApiGetCustomerDto[]>,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const router = useRouter();
    const route = useRoute();
    const modalData = ref(getInitialModalData());
    const orderValues = ref(deepCloneObject(props.order));
    const currentCustomerType = ref<CourseOrderCustomerType>();

    const changeOrderParticipant = useOpenModal(ModalType.Change, "bestiller", modalData);
    const changeOrderInvoiceRecipient = useOpenModal(ModalType.Change, "fakturamottaker", modalData);

    const filteredCustomerPersons = computed(() => {
      const activeCustomerPersons = props.customerPersons?.filter((user) => user.isActive);
      return reduceToOrderCustomer(activeCustomerPersons);
    });

    const filteredCustomerOrganizations = computed(() => {
      const activeCustomerOrganizations = props.customerOrganizations?.filter((organization) => organization.isActive);
      return reduceToOrderCustomer(activeCustomerOrganizations);
    });

    const changeOrderCustomer = (customerType: CourseOrderCustomerType) => {
      currentCustomerType.value = customerType;
      if (customerType === CourseOrderCustomerType.Participant) {
        changeOrderParticipant();
      }
      if (customerType === CourseOrderCustomerType.InvoiceRecipient) {
        changeOrderInvoiceRecipient();
      }
    };

    const updateCustomerOrder = (selectedCustomer: OrderCustomer, customerType: CourseOrderCustomerType) => {
      if (customerType === CourseOrderCustomerType.Participant) {
        orderValues.value.participant = mapToOrderParticipantAndInvoiceRecipient(selectedCustomer);
      }
      if (customerType === CourseOrderCustomerType.InvoiceRecipient) {
        orderValues.value.invoiceRecipient = mapToOrderParticipantAndInvoiceRecipient(selectedCustomer);
      }
      closeOrderCustomer();
    };

    const closeOrderCustomer = () => {
      modalData.value.showModal = false;
      currentCustomerType.value = undefined;
    };

    const submitValues = () => {
      const isValid = getValidatableRef(refs.updateOrderForm)?.validate();
      if (!isValid) {
        return;
      }
      emit("updateOrderValues", orderValues.value);
    };

    const navigateToOrderById = (orderId: string) => {
      router.push({
        name: SingleCourseRouteNames.CourseEconomyDetails,
        params: {
          id: route.params.id,
          orderNo: orderId,
        },
      });
    };

    return {
      labelMap,
      modalData,
      validateIsEmail,
      validateNotEmpty,
      currentCustomerType,
      numberOfPaymentsRule,
      paymentInvervalRule,
      orderValues,
      paymentTypes,
      formatDate,
      submitValues,
      closeOrderCustomer,
      changeOrderCustomer,
      navigateToOrderById,
      updateCustomerOrder,
      getPaymentTypeByType,
      CourseOrderPaymentType,
      CourseOrderCustomerType,
      filteredCustomerPersons,
      filteredCustomerOrganizations,
      norwegianPhonePrefix: intlPhoneCodes.norway,
      isEditableContra: computed(
        () => props.order.contraEntryOrderId && props.order.paymentType !== CourseOrderPaymentType.severalDeductions
      ),
      orderRecipient: computed(() => [
        { value: orderValues.value.participant?.customerNo, headline: labelMap.customerNo },
        { value: orderValues.value.participant?.name, headline: labelMap.name },
        { value: orderValues.value.participant?.orgNo, headline: labelMap.orgNo },
        {
          value: orderValues.value.participant?.telephone,
          headline: labelMap.telephone,
          hide: props.isEdit,
        },
        { value: orderValues.value.participant?.email, headline: labelMap.email, hide: props.isEdit },
        { value: orderValues.value.participant?.address, headline: labelMap.address },
        { value: orderValues.value.participant?.postNo, headline: labelMap.postNo },
        { value: orderValues.value.participant?.postArea, headline: labelMap.postArea },
      ]),
      incoiceRecipient: computed(() => [
        { value: orderValues.value.invoiceRecipient?.customerNo, headline: labelMap.customerNo },
        { value: orderValues.value.invoiceRecipient?.name, headline: labelMap.name },
        { value: orderValues.value.invoiceRecipient?.orgNo, headline: labelMap.orgNo },
        {
          value: orderValues.value.invoiceRecipient?.telephone,
          headline: labelMap.telephone,
          hide: props.isEdit,
        },
        { value: orderValues.value.invoiceRecipient?.email, headline: labelMap.email, hide: props.isEdit },
        { value: orderValues.value.invoiceRecipient?.address, headline: labelMap.address },
        { value: orderValues.value.invoiceRecipient?.postNo, headline: labelMap.postNo },
        { value: orderValues.value.invoiceRecipient?.postArea, headline: labelMap.postArea },
      ]),
      invoice: computed(() => [
        { value: orderValues.value.customerOrderNo, headline: labelMap.customerOrderNo, hide: props.isEdit },
        { value: orderValues.value.theirRef, headline: labelMap.theirRef, hide: props.isEdit },
        {
          value: getPaymentTypeByType(orderValues.value.paymentType),
          headline: labelMap.paymentType,
          hide: props.isEdit,
        },
        { value: orderValues.value.noOfPayments, headline: labelMap.noOfPayments, hide: props.isEdit },
        { value: orderValues.value.paymentInverval, headline: labelMap.paymentInverval, hide: props.isEdit },
        { value: orderValues.value.isInternal, headline: labelMap.isInternal, hide: props.isEdit },
      ]),
    };
  },
});

const numberOfPaymentsRule = [
  (input: number) => !!input || "Feltet er påkrevd",
  (input: number) => (input > 1 && +input <= 18) || "Antall betalinger må være mellom 2 og 18",
];

const paymentInvervalRule = [
  (input: number) => !!input || "Feltet er påkrevd",
  (input: number) => (input >= 1 && +input <= 6) || "Intervallet må være mellom 1 og 6",
];

const paymentTypes = [
  { text: labelMap.singleDeduction, value: CourseOrderPaymentType.singleDeduction },
  { text: labelMap.severalDeductions, value: CourseOrderPaymentType.severalDeductions },
];

const paymentTypeByValue = {
  [CourseOrderPaymentType.singleDeduction]: labelMap.singleDeduction,
  [CourseOrderPaymentType.severalDeductions]: labelMap.severalDeductions,
} as const;

const getPaymentTypeByType = (paymentType: CourseOrderPaymentType) => paymentTypeByValue[paymentType] || "";
