
import { ApiArticleDtoType } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import { CourseOrderPaymentType } from "@/shared/enums/courseOrderPaymentType.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { getArticleName, calculateTotalSum } from "@/shared/helpers/courseOrderHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import { validateNegativeNumber, validateNonNegativeNumber } from "@/shared/helpers/validationHelpers";
import { OrderLineWithUUID, OrderWithUUID } from "@/shared/interfaces/OrderWithUIID.interface";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "EditArticleModal",
  components: { BaseModalForm, BaseSheetField },
  props: {
    modalType: {
      validator: (value: string) => (Object.values(ModalType) as string[]).includes(value),
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    articles: {
      type: Array as PropType<ApiArticleDtoType[]>,
      required: true,
    },
    existingItemModal: {
      type: Object as PropType<OrderLineWithUUID>,
      required: true,
    },
    orderValuesWithUUID: {
      type: Object as PropType<OrderWithUUID>,
      required: true,
    },
  },
  emits: ["close", "updateOrderDraft"],
  setup(props, { emit, refs }) {
    const articleModel = ref<OrderLineWithUUID>(getArticleTemplateInitialValues());

    const existingItemModal = deepCloneObject(props.existingItemModal);
    if (props.existingItemModal) {
      articleModel.value = existingItemModal;
    }

    const validatePercentage = [(input: string) => (+input >= 0 && +input <= 100) || "Må være mellom 0 og 100"];

    const singleOrderWithUUID = deepCloneObject(props.orderValuesWithUUID);

    const handleSubmit = async () => {
      const isValid = getValidatableRef(refs.editArticleForm)?.validate();
      if (!isValid) {
        return;
      }
      await updateOrderLine();
    };

    const updateOrderLine = async () => {
      if (!singleOrderWithUUID.orderLines) {
        return;
      }
      singleOrderWithUUID.orderLines = singleOrderWithUUID.orderLines.map((currentOrderLine) => {
        if (currentOrderLine.temporaryId !== articleModel.value.temporaryId) {
          return currentOrderLine;
        }
        return { ...articleModel.value };
      });
      emit("updateOrderDraft", singleOrderWithUUID, props.modalType, () => emit("close"));
    };

    return {
      articleModel,
      validateNonNegativeNumber,
      validateNegativeNumber,
      validatePercentage,
      getArticleName,
      handleSubmit,
      calculateTotalSum,
      formatCurrency,
      hasUser: computed(() => articleModel.value.user?.name),
      isEditableContra: computed(
        () =>
          props.orderValuesWithUUID.contraEntryOrderId &&
          props.orderValuesWithUUID.paymentType !== CourseOrderPaymentType.severalDeductions
      ),
    };
  },
});

const getArticleTemplateInitialValues = () => ({
  temporaryId: generateUuid(),
  articleId: 0,
  amount: 0,
  discount: 0,
  quantity: 0,
  description: "",
  courseAreaId: 0,
  employeeId: 0,
  articleNo: "",
  projectNo: 0,
  productNo: 0,
  accountingDepartment: 0,
  teachingMethod: 0,
  employeeNo: 0,
});
