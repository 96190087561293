export enum CourseOrderStatus {
  DraftValue = 1,
  ValidatedValue = 2,
  QueueingValue = 3,
  QueuedValue = 4,
  SendingValue = 5,
  SendtValue = 6,
  FailedSendingValue = 7,
  FailedOrderValue = 8,
  CanceledValue = 9,
  FailedQueueingValue = 10,
}
