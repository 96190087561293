
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { CourseOrderCustomerType } from "@/shared/enums/CourseOrderCustomerType.enum";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { OrderCustomer } from "@/shared/helpers/courseOrderHelpers";

export default defineComponent({
  name: "ChangeOrderCustomerModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    customerType: {
      type: String as PropType<CourseOrderCustomerType>,
      require: true,
    },
    customerOrganizations: {
      type: Array as PropType<OrderCustomer[]>,
      required: true,
    },
    customerPersons: {
      type: Array as PropType<OrderCustomer[]>,
      required: true,
    },
  },
  emits: ["close", "updateCustomerOrder"],
  setup(props, { emit, refs }) {
    const selectedOrderCustomer = ref<OrderCustomer>();
    const isCustomerOrganization = ref(true);

    const getCustomerName = (item: OrderCustomer) =>
      item.birthDate ? `${item.name} - ${item.email} - ${formatDate(item.birthDate)}` : `${item.name}`;

    const handleSubmit = () => {
      const isValid = getValidatableRef(refs.form)?.validate();
      if (!isValid) {
        return;
      }
      emit("updateCustomerOrder", selectedOrderCustomer.value, props.customerType);
    };

    watch(isCustomerOrganization, () => (selectedOrderCustomer.value = undefined));

    return {
      handleSubmit,
      selectedOrderCustomer,
      validateNotEmpty,
      isCustomerOrganization,
      getCustomerName,
    };
  },
});
