var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "updateOrderForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_vm.modalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 600
    },
    on: {
      "close": _vm.closeOrderCustomer
    }
  }, [_c('ChangeOrderCustomerModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "customerType": _vm.currentCustomerType,
      "customerOrganizations": _vm.filteredCustomerOrganizations,
      "customerPersons": _vm.filteredCustomerPersons
    },
    on: {
      "close": _vm.closeOrderCustomer,
      "updateCustomerOrder": _vm.updateCustomerOrder
    }
  })], 1) : _vm._e(), _c('v-row', {
    class: "ma-1 ".concat(_vm.modalData.showModal && 'mt-7')
  }, [_vm.orderValues.id ? _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('BaseIconCard', {
    attrs: {
      "value": _vm.orderValues.id,
      "link": false,
      "headline": _vm.labelMap.id,
      "captionAbove": "",
      "dense": ""
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_vm.orderValues.status ? _c('BaseIconCard', {
    attrs: {
      "value": _vm.orderValues.status.name,
      "link": false,
      "headline": _vm.labelMap.status,
      "captionAbove": "",
      "dense": ""
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_vm.orderValues.courseStartDate ? _c('BaseIconCard', {
    attrs: {
      "value": _vm.formatDate(_vm.orderValues.courseStartDate),
      "link": false,
      "headline": _vm.labelMap.courseStartDate,
      "captionAbove": "",
      "dense": ""
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_vm.orderValues.courseEndDate ? _c('BaseIconCard', {
    attrs: {
      "value": _vm.formatDate(_vm.orderValues.courseEndDate),
      "link": false,
      "headline": _vm.labelMap.courseEndDate,
      "captionAbove": "",
      "dense": ""
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "title": "Bestiller"
    }
  }, [_vm.isEdit && !_vm.isEditableContra ? _c('span', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.changeOrderCustomer(_vm.CourseOrderCustomerType.Participant);
      }
    }
  }, [_vm._v(" endre bestiller "), _c('v-icon', {
    staticClass: "pl-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1) : _vm._e()])], 1), _vm._l(_vm.orderRecipient, function (item, i) {
    return _c('v-col', {
      key: "orderRecipient-".concat(i),
      staticClass: "pa-1",
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "4",
        "lg": "3"
      }
    }, [!item.hide || _vm.isEditableContra ? _c('BaseIconCard', _vm._b({
      attrs: {
        "link": false,
        "captionAbove": "",
        "dense": ""
      }
    }, 'BaseIconCard', item, false)) : _vm._e(), item.hide && item.value === _vm.orderValues.participant.telephone && !_vm.isEditableContra ? _c('v-phone-input', {
      attrs: {
        "outlined": "",
        "rules": _vm.validateNotEmpty,
        "country-icon-mode": "svg"
      },
      model: {
        value: _vm.orderValues.participant.telephone,
        callback: function callback($$v) {
          _vm.$set(_vm.orderValues.participant, "telephone", $$v);
        },
        expression: "orderValues.participant.telephone"
      }
    }) : _vm._e(), item.hide && item.value === _vm.orderValues.participant.email && !_vm.isEditableContra ? _c('v-text-field', {
      attrs: {
        "outlined": "",
        "label": _vm.labelMap.email,
        "rules": _vm.validateIsEmail
      },
      model: {
        value: _vm.orderValues.participant.email,
        callback: function callback($$v) {
          _vm.$set(_vm.orderValues.participant, "email", $$v);
        },
        expression: "orderValues.participant.email"
      }
    }) : _vm._e()], 1);
  }), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "title": "Fakturamottaker"
    }
  }, [_vm.isEdit && !_vm.isEditableContra ? _c('span', {
    staticClass: "pt-2"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.changeOrderCustomer(_vm.CourseOrderCustomerType.InvoiceRecipient);
      }
    }
  }, [_vm._v(" endre fakturamottaker "), _c('v-icon', {
    staticClass: "pl-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1) : _vm._e()])], 1), _vm._l(_vm.incoiceRecipient, function (item, i) {
    return _c('v-col', {
      key: "incoiceRecipient-".concat(i),
      staticClass: "pa-1",
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "4",
        "lg": "3"
      }
    }, [!item.hide || _vm.isEditableContra ? _c('BaseIconCard', _vm._b({
      attrs: {
        "link": false,
        "captionAbove": "",
        "dense": ""
      }
    }, 'BaseIconCard', item, false)) : _vm._e(), item.hide && item.value === _vm.orderValues.invoiceRecipient.telephone && !_vm.isEditableContra ? _c('v-phone-input', {
      attrs: {
        "outlined": "",
        "rules": _vm.validateNotEmpty,
        "country-icon-mode": "svg"
      },
      model: {
        value: _vm.orderValues.invoiceRecipient.telephone,
        callback: function callback($$v) {
          _vm.$set(_vm.orderValues.invoiceRecipient, "telephone", $$v);
        },
        expression: "orderValues.invoiceRecipient.telephone"
      }
    }) : _vm._e(), item.hide && item.value === _vm.orderValues.invoiceRecipient.email && !_vm.isEditableContra ? _c('v-text-field', {
      attrs: {
        "outlined": "",
        "label": _vm.labelMap.email,
        "rules": _vm.validateIsEmail
      },
      model: {
        value: _vm.orderValues.invoiceRecipient.email,
        callback: function callback($$v) {
          _vm.$set(_vm.orderValues.invoiceRecipient, "email", $$v);
        },
        expression: "orderValues.invoiceRecipient.email"
      }
    }) : _vm._e()], 1);
  }), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('BaseSheetHeader', {
    attrs: {
      "title": "Ordredetaljer"
    }
  })], 1), _vm._l(_vm.invoice, function (item, i) {
    return _c('v-col', {
      key: "invoice-".concat(i),
      staticClass: "pa-1",
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "4",
        "lg": "3"
      }
    }, [!item.hide ? _c('BaseIconCard', _vm._b({
      attrs: {
        "link": false,
        "captionAbove": "",
        "dense": ""
      }
    }, 'BaseIconCard', item, false)) : _vm._e(), item.hide && item.headline === _vm.labelMap.customerOrderNo ? _c('v-text-field', {
      attrs: {
        "label": item.headline,
        "maxlength": "50",
        "counter": "",
        "outlined": ""
      },
      model: {
        value: _vm.orderValues.customerOrderNo,
        callback: function callback($$v) {
          _vm.$set(_vm.orderValues, "customerOrderNo", $$v);
        },
        expression: "orderValues.customerOrderNo"
      }
    }) : _vm._e(), item.hide && item.headline === _vm.labelMap.theirRef ? _c('v-text-field', {
      attrs: {
        "label": item.headline,
        "rules": _vm.validateNotEmpty,
        "counter": "",
        "maxlength": "30",
        "outlined": ""
      },
      model: {
        value: _vm.orderValues.theirRef,
        callback: function callback($$v) {
          _vm.$set(_vm.orderValues, "theirRef", $$v);
        },
        expression: "orderValues.theirRef"
      }
    }) : _vm._e(), item.hide && item.headline === _vm.labelMap.paymentType && !_vm.isEditableContra ? _c('v-select', {
      attrs: {
        "label": item.headline,
        "items": _vm.paymentTypes,
        "item-text": "text",
        "item-value": "value",
        "outlined": ""
      },
      model: {
        value: _vm.orderValues.paymentType,
        callback: function callback($$v) {
          _vm.$set(_vm.orderValues, "paymentType", $$v);
        },
        expression: "orderValues.paymentType"
      }
    }) : _vm._e(), item.hide && item.headline === _vm.labelMap.noOfPayments && _vm.orderValues.paymentType === _vm.CourseOrderPaymentType.severalDeductions && !_vm.isEditableContra ? _c('v-text-field', {
      attrs: {
        "label": item.headline,
        "rules": _vm.numberOfPaymentsRule,
        "type": "number",
        "outlined": ""
      },
      model: {
        value: _vm.orderValues.noOfPayments,
        callback: function callback($$v) {
          _vm.$set(_vm.orderValues, "noOfPayments", $$v);
        },
        expression: "orderValues.noOfPayments"
      }
    }) : _vm._e(), item.hide && item.headline === _vm.labelMap.paymentInverval && _vm.orderValues.paymentType === _vm.CourseOrderPaymentType.severalDeductions && !_vm.isEditableContra ? _c('v-text-field', {
      attrs: {
        "label": item.headline,
        "rules": _vm.paymentInvervalRule,
        "type": "number",
        "outlined": ""
      },
      model: {
        value: _vm.orderValues.paymentInverval,
        callback: function callback($$v) {
          _vm.$set(_vm.orderValues, "paymentInverval", $$v);
        },
        expression: "orderValues.paymentInverval"
      }
    }) : _vm._e(), item.hide && item.headline === _vm.labelMap.isInternal && !_vm.isEditableContra ? _c('v-switch', {
      staticClass: "ml-2",
      attrs: {
        "label": item.headline,
        "inset": ""
      },
      model: {
        value: _vm.orderValues.isInternal,
        callback: function callback($$v) {
          _vm.$set(_vm.orderValues, "isInternal", $$v);
        },
        expression: "orderValues.isInternal"
      }
    }) : _vm._e()], 1);
  }), _vm.orderValues.contraEntryOrderId ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('p', {
    staticClass: "ma-2 text-lg grey--text text--darken-1"
  }, [_vm._v(" " + _vm._s(_vm.labelMap.contraEntryOrderId) + ": "), _c('v-chip', {
    staticClass: "mx-1",
    attrs: {
      "label": ""
    },
    on: {
      "click": function click($event) {
        return _vm.navigateToOrderById(_vm.orderValues.contraEntryOrderId);
      }
    }
  }, [_vm._v("Ordre - " + _vm._s(_vm.orderValues.contraEntryOrderId))])], 1)]) : _vm._e(), _vm.orderValues.contraEntryOrdersId && _vm.orderValues.contraEntryOrdersId.length ? _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('p', {
    staticClass: "ma-2 text-lg grey--text text--darken-1"
  }, [_vm._v(" " + _vm._s(_vm.labelMap.contraEntryOrdersId) + ": "), _vm._l(_vm.orderValues.contraEntryOrdersId, function (contraEntryOrderId) {
    return _c('v-chip', {
      key: contraEntryOrderId,
      staticClass: "mx-1",
      attrs: {
        "label": ""
      },
      on: {
        "click": function click($event) {
          return _vm.navigateToOrderById(contraEntryOrderId);
        }
      }
    }, [_vm._v("Ordre - " + _vm._s(contraEntryOrderId))]);
  })], 2)]) : _vm._e()], 2), _vm.isEdit ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('cancelEdit');
      }
    }
  }, [_vm._v("Avbryt")]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.submitValues
    }
  }, [_vm._v("Lagre")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }