var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "editArticleForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Artikkelnummer",
      "value": _vm.articleModel.articleNo,
      "disabled": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Artikkelnavn",
      "value": _vm.getArticleName(_vm.articleModel.articleNo, _vm.articles),
      "disabled": ""
    }
  })], 1), _vm.hasUser ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Deltaker",
      "value": _vm.articleModel.user.name,
      "disabled": ""
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Enhetspris",
      "rules": _vm.validateNonNegativeNumber
    },
    model: {
      value: _vm.articleModel.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.articleModel, "amount", $$v);
      },
      expression: "articleModel.amount"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Antall",
      "rules": _vm.isEditableContra ? _vm.validateNegativeNumber : _vm.validateNonNegativeNumber
    },
    model: {
      value: _vm.articleModel.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.articleModel, "quantity", $$v);
      },
      expression: "articleModel.quantity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Rabatt",
      "rules": _vm.validatePercentage,
      "type": "number",
      "suffix": "%"
    },
    model: {
      value: _vm.articleModel.discount,
      callback: function callback($$v) {
        _vm.$set(_vm.articleModel, "discount", $$v);
      },
      expression: "articleModel.discount"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Total sum",
      "value": _vm.calculateTotalSum(_vm.articleModel),
      "disabled": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Beskrivelse",
      "counter": "",
      "maxlength": "50"
    },
    model: {
      value: _vm.articleModel.description,
      callback: function callback($$v) {
        _vm.$set(_vm.articleModel, "description", $$v);
      },
      expression: "articleModel.description"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.formatCurrency(_vm.articleModel.previouslyInvoicedAmount),
      "label": "Tidligere fakturert på artikkel"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.formatCurrency(_vm.articleModel.previouslyCreditedAmount),
      "label": "Tidligere kreditert på artikkel"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }