var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": _vm.closeModal
    }
  }, [_c('EditArticleModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "existingItemModal": _vm.modalData.existingItem,
      "orderValuesWithUUID": _vm.orderValuesWithUUID,
      "articles": _vm.articles
    },
    on: {
      "close": _vm.closeModal,
      "updateOrderDraft": _vm.updateOrderDraft
    }
  })], 1) : _vm._e(), _vm.deleteModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    },
    on: {
      "close": function close($event) {
        _vm.deleteModalData.showModal = false;
      }
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.deleteModalData
    },
    on: {
      "close": function close($event) {
        _vm.deleteModalData.showModal = false;
      },
      "submit": _vm.deleteArticle
    }
  }, [_vm._v(" Er du sikker på at du vil fjerne artikkelen fra " + _vm._s(_vm.isEditableContra ? "kreditordren" : "ordren") + "? ")])], 1) : _vm._e(), _vm.createModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 600
    },
    on: {
      "close": function close($event) {
        _vm.createModalData.showModal = false;
      }
    }
  }, [_c('NewArticleModal', {
    attrs: {
      "headline": _vm.createModalData.modalHeadline,
      "modalType": _vm.createModalData.modalType,
      "articles": _vm.filteredArticles,
      "orderValuesWithUUID": _vm.orderValuesWithUUID
    },
    on: {
      "close": function close($event) {
        _vm.createModalData.showModal = false;
      },
      "updateOrderDraft": _vm.updateOrderDraft
    }
  })], 1) : _vm._e(), _c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.orderLinesWithUUID
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light text-center",
          staticStyle: {
            "align-self": "center"
          },
          attrs: {
            "data-cy": "titleCourse"
          }
        }, [_vm._v("Artikler")]), _c('v-spacer'), _vm.isEditable && !_vm.isEditableContra ? _c('BaseTooltipIconButton', {
          attrs: {
            "color": "primary",
            "btnText": "Ny artikkel",
            "icon": "mdi-plus",
            "dataCy": "addArticleButton"
          },
          on: {
            "handleClick": _vm.addArticle
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "editArticle"
                },
                on: {
                  "click": function click($event) {
                    return _vm.editArticle(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-pencil")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger artikkel")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "deleteArticle"
                },
                on: {
                  "click": function click($event) {
                    return _vm.showDeleteArticleModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-delete")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Slett artikkel")])])];
      }
    }, {
      key: "item.articleName",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.getArticleName(item.articleNo, _vm.articles)) + " ")];
      }
    }, {
      key: "item.totalAmount",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.calculateTotalSum(item))) + " ")];
      }
    }, {
      key: "item.amount",
      fn: function fn(_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(_vm.formatCurrency(value)) + " ")];
      }
    }, {
      key: "item.description",
      fn: function fn(_ref7) {
        var value = _ref7.value;
        return [_vm._v(" " + _vm._s(value || "Ingen beskrivelse") + " ")];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }