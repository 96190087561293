var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "addArticleForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "data-cy": "participants",
      "label": "Deltaker",
      "items": _vm.courseParticipants,
      "rules": _vm.validateNotEmpty,
      "item-text": "userFullName",
      "item-value": "userId"
    },
    model: {
      value: _vm.selectedParticipantUserId,
      callback: function callback($$v) {
        _vm.selectedParticipantUserId = $$v;
      },
      expression: "selectedParticipantUserId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "data-cy": "articles",
      "label": "Artikler",
      "items": _vm.articles,
      "rules": _vm.validateArrayNotEmpty,
      "multiple": "",
      "item-text": "name",
      "item-value": "id"
    },
    model: {
      value: _vm.selectedArticles,
      callback: function callback($$v) {
        _vm.selectedArticles = $$v;
      },
      expression: "selectedArticles"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }